<template>
    <v-dialog v-model="visible" persistent width="900px">
        <v-card>
            <v-card-title class="justify-center">
                <v-spacer/>
                <span class="text-h5">{{ form.id ? 'Edição' : 'Cadastro' }} de Público</span>
                <v-spacer/>
                <v-btn icon @click="$emit('close')">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text
                align="center"
                justify="center"
                :style="`height: ${$vuetify.breakpoint.height - 400}px; overflow-y: auto;`"
            >
                <v-text-field
                    label="Nome"
                    v-model="form.nome"
                    outlined
                    dense
                    hide-details
                    class="mt-4"
                />
                <v-select
                    v-if="usuario.tipo === 'suporte'"
                    @change="onFonteChange"
                    :items="fontes"
                    v-model="form.fonte"
                    label="Fonte de dados"
                    dense
                    outlined
                    hide-details
                    class="mt-4"
                />

                <div v-if="form.fonte === 'csv'" class="mt-4" style="border: solid 1px #999; border-radius: 5px;">
                    <b>Arquivo CSV</b>
                    <FileInput ref="file" v-model="form.arquivo" />
                </div>

                <div v-if="form.fonte === 'clientes'">
                    <div class="my-2" style="display: flex; align-items: center;">
                        Clientes que combinam com
                        <v-select
                            :items="['Qualquer', 'Todas']"
                            v-model="form.operador_condicional"
                            dense
                            outlined
                            hide-details
                            class="mx-2"
                            style="max-width: 130px;"
                        />
                        uma das seguintes condições:
                    </div>
                    <div>
                        <div
                            v-for="(condicao, i) in form.condicoes"
                            :key="i"
                            style="display: flex; align-items: baseline;"
                            class="my-2"
                        >
                            <b class="mr-2">{{ form.operador_condicional === 'Qualquer' ? 'OU' : 'E' }}</b>
                            <v-select
                                @change="form.condicoes[i][form.condicoes[i].type] = {}"
                                :items="condicoesClientes"
                                v-model="form.condicoes[i].type"
                                dense
                                outlined
                                hide-details
                                class="mx-2"
                                style="max-width: 160px;"
                            />
                            <div v-if="form.condicoes[i].type === 'PEDIDOS'" style="display: flex; flex-wrap: wrap;">
                                <v-select
                                    @change="$forceUpdate()"
                                    :items="numberOptions"
                                    v-model="form.condicoes[i].PEDIDOS.operador_quantidade"
                                    dense
                                    outlined
                                    hide-details
                                    class="mx-2"
                                    style="max-width: 140px;"
                                />
                                <v-text-field
                                    v-if="['MAIOR_QUE', 'ENTRE'].includes(form.condicoes[i].PEDIDOS.operador_quantidade)"
                                    v-model="form.condicoes[i].PEDIDOS.quantidade_inicial"
                                    v-mask="['####']"
                                    outlined
                                    dense
                                    hide-details
                                    style="max-width: 60px;"
                                />
                                <v-text-field
                                    v-if="['MENOR_QUE', 'ENTRE'].includes(form.condicoes[i].PEDIDOS.operador_quantidade)"
                                    v-model="form.condicoes[i].PEDIDOS.quantidade_final"
                                    v-mask="['####']"
                                    outlined
                                    dense
                                    hide-details
                                    style="max-width: 60px;"
                                />
                                <v-select
                                    @change="$forceUpdate()"
                                    :items="dateOptions"
                                    v-model="form.condicoes[i].PEDIDOS.operador_periodo"
                                    dense
                                    outlined
                                    hide-details
                                    class="mx-2"
                                    style="max-width: 140px;"
                                />
                                <v-text-field
                                    v-if="['ANTES_DE', 'ENTRE'].includes(form.condicoes[i].PEDIDOS.operador_periodo)"
                                    v-model="form.condicoes[i].PEDIDOS.data_inicial"
                                    type="date"
                                    outlined
                                    dense
                                    hide-details
                                    style="max-width: 160px;"
                                />
                                <v-text-field
                                    v-if="['DEPOIS_DE', 'ENTRE'].includes(form.condicoes[i].PEDIDOS.operador_periodo)"
                                    v-model="form.condicoes[i].PEDIDOS.data_final"
                                    type="date"
                                    outlined
                                    dense
                                    hide-details
                                    style="max-width: 160px;"
                                />
                            </div>
                            <!-- <div v-if="form.condicoes[i].type === 'CLIENTE_POTENCIAL'" style="display: flex; flex-wrap: wrap;">
                                <v-select
                                    @change="$forceUpdate()"
                                    :items="booleanOptions"
                                    v-model="form.condicoes[i].CLIENTE_POTENCIAL.valor"
                                    dense
                                    outlined
                                    hide-details
                                    class="mx-2"
                                    style="max-width: 140px;"
                                />
                            </div> -->
                            <div v-if="form.condicoes[i].type === 'VALOR_GASTO'" style="display: flex; flex-wrap: wrap;">
                                <v-select
                                    @change="$forceUpdate()"
                                    :items="numberOptions"
                                    v-model="form.condicoes[i].VALOR_GASTO.operador_valor"
                                    dense
                                    outlined
                                    hide-details
                                    class="mx-2"
                                    style="max-width: 140px;"
                                />
                                <v-text-field
                                    v-if="['MAIOR_QUE', 'ENTRE'].includes(form.condicoes[i].VALOR_GASTO.operador_valor)"
                                    v-model="form.condicoes[i].VALOR_GASTO.valor_inicial"
                                    v-mask="['####']"
                                    outlined
                                    dense
                                    hide-details
                                    style="max-width: 60px;"
                                />
                                <v-text-field
                                    v-if="['MENOR_QUE', 'ENTRE'].includes(form.condicoes[i].VALOR_GASTO.operador_valor)"
                                    v-model="form.condicoes[i].VALOR_GASTO.valor_final"
                                    v-mask="['####']"
                                    outlined
                                    dense
                                    hide-details
                                    style="max-width: 60px;"
                                />
                                <v-select
                                    @change="$forceUpdate()"
                                    :items="dateOptions"
                                    v-model="form.condicoes[i].VALOR_GASTO.operador_periodo"
                                    dense
                                    outlined
                                    hide-details
                                    class="mx-2"
                                    style="max-width: 140px;"
                                />
                                <v-text-field
                                    v-if="['ANTES_DE', 'ENTRE'].includes(form.condicoes[i].VALOR_GASTO.operador_periodo)"
                                    v-model="form.condicoes[i].VALOR_GASTO.data_inicial"
                                    type="date"
                                    outlined
                                    dense
                                    hide-details
                                    style="max-width: 160px;"
                                />
                                <v-text-field
                                    v-if="['DEPOIS_DE', 'ENTRE'].includes(form.condicoes[i].VALOR_GASTO.operador_periodo)"
                                    v-model="form.condicoes[i].VALOR_GASTO.data_final"
                                    type="date"
                                    outlined
                                    dense
                                    hide-details
                                    style="max-width: 160px;"
                                />
                            </div>

                            <div v-if="form.condicoes[i].type === 'DIAS_SEM_COMPRAR'" style="display: flex; flex-wrap: wrap;">
                                <v-select
                                    @change="$forceUpdate()"
                                    :items="numberOptions"
                                    v-model="form.condicoes[i].DIAS_SEM_COMPRAR.operador_valor"
                                    dense
                                    outlined
                                    hide-details
                                    class="mx-2"
                                    style="max-width: 140px;"
                                />
                                <v-text-field
                                    v-if="['MAIOR_QUE', 'ENTRE'].includes(form.condicoes[i].DIAS_SEM_COMPRAR.operador_valor)"
                                    v-model="form.condicoes[i].DIAS_SEM_COMPRAR.valor_inicial"
                                    v-mask="['####']"
                                    outlined
                                    dense
                                    hide-details
                                    style="max-width: 60px;"
                                />
                                <v-text-field
                                    v-if="['MENOR_QUE', 'ENTRE'].includes(form.condicoes[i].DIAS_SEM_COMPRAR.operador_valor)"
                                    v-model="form.condicoes[i].DIAS_SEM_COMPRAR.valor_final"
                                    v-mask="['####']"
                                    outlined
                                    dense
                                    hide-details
                                    style="max-width: 60px;"
                                />
                            </div>

                            <div v-if="form.condicoes[i].type === 'CLIENTE'" style="display: flex; flex-wrap: wrap;">
                                <v-select
                                    @change="$forceUpdate()"
                                    :items="numberOptions"
                                    v-model="form.condicoes[i].CLIENTE.operador_codigo"
                                    dense
                                    outlined
                                    hide-details
                                    class="mx-2"
                                    style="max-width: 140px;"
                                />
                                <v-text-field
                                    v-if="['MAIOR_QUE', 'ENTRE'].includes(form.condicoes[i].CLIENTE.operador_codigo)"
                                    v-model="form.condicoes[i].CLIENTE.codigo_inicial"
                                    v-mask="['####']"
                                    outlined
                                    dense
                                    hide-details
                                    style="max-width: 60px;"
                                />
                                <v-text-field
                                    v-if="['MENOR_QUE', 'ENTRE'].includes(form.condicoes[i].CLIENTE.operador_codigo)"
                                    v-model="form.condicoes[i].CLIENTE.codigo_final"
                                    v-mask="['####']"
                                    outlined
                                    dense
                                    hide-details
                                    style="max-width: 60px;"
                                />
                            </div>

                            <v-btn @click="form.condicoes.splice(i, 1)" small color="error" icon>
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </div>
                    </div>
                </div>

                <div v-if="form.fonte === 'contatos'">
                    <div class="my-2" style="display: flex; align-items: center;">
                        Contatos que combinam com
                        <v-select
                            :items="['Qualquer', 'Todas']"
                            v-model="form.operador_condicional"
                            dense
                            outlined
                            hide-details
                            class="mx-2"
                            style="max-width: 130px;"
                        />
                        uma das seguintes condições:
                    </div>
                    <div>
                        <div
                            v-for="(condicao, i) in form.condicoes"
                            :key="i"
                            style="display: flex; align-items: baseline;"
                            class="my-2"
                        >
                            <b class="mr-2">{{ form.operador_condicional === 'Qualquer' ? 'OU' : 'E' }}</b>
                            <v-select
                                @change="form.condicoes[i][form.condicoes[i].type] = {}"
                                :items="condicoesContatos"
                                v-model="form.condicoes[i].type"
                                dense
                                outlined
                                hide-details
                                class="mx-2"
                                style="max-width: 160px;"
                            />
                            <div v-if="form.condicoes[i].type === 'CONTATO_CODIGO'" style="display: flex; flex-wrap: wrap;">
                                <v-select
                                    @change="$forceUpdate()"
                                    :items="numberOptions"
                                    v-model="form.condicoes[i].CONTATO_CODIGO.operador_codigo"
                                    dense
                                    outlined
                                    hide-details
                                    class="mx-2"
                                    style="max-width: 140px;"
                                />
                                <v-text-field
                                    v-if="['MAIOR_QUE', 'ENTRE'].includes(form.condicoes[i].CONTATO_CODIGO.operador_codigo)"
                                    v-model="form.condicoes[i].CONTATO_CODIGO.codigo_inicial"
                                    v-mask="['######']"
                                    outlined
                                    dense
                                    hide-details
                                    style="max-width: 80px;"
                                />
                                <v-text-field
                                    v-if="['MENOR_QUE', 'ENTRE'].includes(form.condicoes[i].CONTATO_CODIGO.operador_codigo)"
                                    v-model="form.condicoes[i].CONTATO_CODIGO.codigo_final"
                                    v-mask="['######']"
                                    outlined
                                    dense
                                    hide-details
                                    style="max-width: 80px;"
                                />
                            </div>
                            <div v-if="form.condicoes[i].type === 'CONTATO_CRIADO_EM'" style="display: flex; flex-wrap: wrap;">
                                <v-select
                                    @change="$forceUpdate()"
                                    :items="dateOptions"
                                    v-model="form.condicoes[i].CONTATO_CRIADO_EM.operador_periodo"
                                    dense
                                    outlined
                                    hide-details
                                    class="mx-2"
                                    style="max-width: 140px;"
                                />
                                <v-text-field
                                    v-if="['ANTES_DE', 'ENTRE'].includes(form.condicoes[i].CONTATO_CRIADO_EM.operador_periodo)"
                                    v-model="form.condicoes[i].CONTATO_CRIADO_EM.data_inicial"
                                    type="date"
                                    outlined
                                    dense
                                    hide-details
                                    style="max-width: 160px;"
                                />
                                <v-text-field
                                    v-if="['DEPOIS_DE', 'ENTRE'].includes(form.condicoes[i].CONTATO_CRIADO_EM.operador_periodo)"
                                    v-model="form.condicoes[i].CONTATO_CRIADO_EM.data_final"
                                    type="date"
                                    outlined
                                    dense
                                    hide-details
                                    style="max-width: 160px;"
                                />
                            </div>
                            <div v-if="form.condicoes[i].type === 'CONTATO_RAMO'" style="display: flex; flex-wrap: wrap;">
                                <v-select
                                    @change="$forceUpdate()"
                                    :items="textOptions"
                                    v-model="form.condicoes[i].CONTATO_RAMO.operador_ramo"
                                    dense
                                    outlined
                                    hide-details
                                    class="mx-2"
                                    style="max-width: 140px;"
                                />
                                <v-text-field
                                    v-if="form.condicoes[i].CONTATO_RAMO.operador_ramo === 'IGUAL'"
                                    v-model="form.condicoes[i].CONTATO_RAMO.ramo_igual"
                                    outlined
                                    dense
                                    hide-details
                                    style="max-width: 180px;"
                                />
                                <v-text-field
                                    v-if="form.condicoes[i].CONTATO_RAMO.operador_ramo === 'CONTEM'"
                                    v-model="form.condicoes[i].CONTATO_RAMO.ramo_contem"
                                    outlined
                                    dense
                                    hide-details
                                    style="max-width: 180px;"
                                />
                            </div>
                            <div v-if="form.condicoes[i].type === 'CONTATO_PEDIDOS'" style="display: flex; flex-wrap: wrap;">
                                <v-select
                                    @change="$forceUpdate()"
                                    :items="textOptions"
                                    v-model="form.condicoes[i].CONTATO_PEDIDOS.operador_pedidos"
                                    dense
                                    outlined
                                    hide-details
                                    class="mx-2"
                                    style="max-width: 140px;"
                                />
                                <v-text-field
                                    v-if="form.condicoes[i].CONTATO_PEDIDOS.operador_pedidos === 'IGUAL'"
                                    v-model="form.condicoes[i].CONTATO_PEDIDOS.pedidos_igual"
                                    outlined
                                    dense
                                    hide-details
                                    style="max-width: 180px;"
                                />
                                <v-text-field
                                    v-if="form.condicoes[i].CONTATO_PEDIDOS.operador_pedidos === 'CONTEM'"
                                    v-model="form.condicoes[i].CONTATO_PEDIDOS.pedidos_contem"
                                    outlined
                                    dense
                                    hide-details
                                    style="max-width: 180px;"
                                />
                            </div>
                            <div v-if="form.condicoes[i].type === 'CONTATO_DIAS_CADASTRADO'" style="display: flex; flex-wrap: wrap;">
                                <v-select
                                    @change="$forceUpdate()"
                                    :items="numberOptions"
                                    v-model="form.condicoes[i].CONTATO_DIAS_CADASTRADO.operador_valor"
                                    dense
                                    outlined
                                    hide-details
                                    class="mx-2"
                                    style="max-width: 140px;"
                                />
                                <v-text-field
                                    v-if="['MAIOR_QUE', 'ENTRE'].includes(form.condicoes[i].CONTATO_DIAS_CADASTRADO.operador_valor)"
                                    v-model="form.condicoes[i].CONTATO_DIAS_CADASTRADO.valor_inicial"
                                    v-mask="['####']"
                                    outlined
                                    dense
                                    hide-details
                                    style="max-width: 60px;"
                                />
                                <v-text-field
                                    v-if="['MENOR_QUE', 'ENTRE'].includes(form.condicoes[i].CONTATO_DIAS_CADASTRADO.operador_valor)"
                                    v-model="form.condicoes[i].CONTATO_DIAS_CADASTRADO.valor_final"
                                    v-mask="['####']"
                                    outlined
                                    dense
                                    hide-details
                                    style="max-width: 60px;"
                                />
                            </div>
                            <v-btn @click="form.condicoes.splice(i, 1)" small color="error" icon>
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </div>
                    </div>
                </div>

                <div v-if="form.fonte === 'empresas'">
                    <div class="my-2" style="display: flex; align-items: center;">
                        Empresas que combinam com
                        <v-select
                            :items="['Qualquer', 'Todas']"
                            v-model="form.operador_condicional"
                            dense
                            outlined
                            hide-details
                            class="mx-2"
                            style="max-width: 130px;"
                        />
                        uma das seguintes condições:
                    </div>
                    <div>
                        <div
                            v-for="(condicao, i) in form.condicoes"
                            :key="i"
                            style="display: flex; align-items: baseline;"
                            class="my-2"
                        >
                            <b class="mr-2">{{ form.operador_condicional === 'Qualquer' ? 'OU' : 'E' }}</b>
                            <v-select
                                @change="form.condicoes[i][form.condicoes[i].type] = {}"
                                :items="condicoesEmpresas"
                                v-model="form.condicoes[i].type"
                                dense
                                outlined
                                hide-details
                                class="mx-2"
                                style="max-width: 160px;"
                            />
                            <div v-if="form.condicoes[i].type === 'EMPRESA_CODIGO'" style="display: flex; flex-wrap: wrap;">
                                <v-select
                                    @change="$forceUpdate()"
                                    :items="numberOptions"
                                    v-model="form.condicoes[i].EMPRESA_CODIGO.operador_codigo"
                                    dense
                                    outlined
                                    hide-details
                                    class="mx-2"
                                    style="max-width: 140px;"
                                />
                                <v-text-field
                                    v-if="['MAIOR_QUE', 'ENTRE'].includes(form.condicoes[i].EMPRESA_CODIGO.operador_codigo)"
                                    v-model="form.condicoes[i].EMPRESA_CODIGO.codigo_inicial"
                                    v-mask="['######']"
                                    outlined
                                    dense
                                    hide-details
                                    style="max-width: 80px;"
                                />
                                <v-text-field
                                    v-if="['MENOR_QUE', 'ENTRE'].includes(form.condicoes[i].EMPRESA_CODIGO.operador_codigo)"
                                    v-model="form.condicoes[i].EMPRESA_CODIGO.codigo_final"
                                    v-mask="['######']"
                                    outlined
                                    dense
                                    hide-details
                                    style="max-width: 80px;"
                                />
                            </div>

                            <div v-if="form.condicoes[i].type === 'EMPRESA_ATIVO'" style="display: flex; flex-wrap: wrap;">
                                <v-select
                                    @change="$forceUpdate()"
                                    :items="booleanOptions"
                                    v-model="form.condicoes[i].EMPRESA_ATIVO.operador_ativo"
                                    dense
                                    outlined
                                    hide-details
                                    class="mx-2"
                                    style="max-width: 140px;"
                                />
                            </div>

                            <div v-if="form.condicoes[i].type === 'EMPRESA_INADIMPLENTE'" style="display: flex; flex-wrap: wrap;">
                                <v-select
                                    @change="$forceUpdate()"
                                    :items="booleanOptions"
                                    v-model="form.condicoes[i].EMPRESA_INADIMPLENTE.operador_inadimplente"
                                    dense
                                    outlined
                                    hide-details
                                    class="mx-2"
                                    style="max-width: 140px;"
                                />
                            </div>

                            <div v-if="form.condicoes[i].type === 'EMPRESA_CRIADO_EM'" style="display: flex; flex-wrap: wrap;">
                                <v-select
                                    @change="$forceUpdate()"
                                    :items="dateOptions"
                                    v-model="form.condicoes[i].EMPRESA_CRIADO_EM.operador_periodo"
                                    dense
                                    outlined
                                    hide-details
                                    class="mx-2"
                                    style="max-width: 140px;"
                                />
                                <v-text-field
                                    v-if="['ANTES_DE', 'ENTRE'].includes(form.condicoes[i].EMPRESA_CRIADO_EM.operador_periodo)"
                                    v-model="form.condicoes[i].EMPRESA_CRIADO_EM.data_inicial"
                                    type="date"
                                    outlined
                                    dense
                                    hide-details
                                    style="max-width: 160px;"
                                />
                                <v-text-field
                                    v-if="['DEPOIS_DE', 'ENTRE'].includes(form.condicoes[i].EMPRESA_CRIADO_EM.operador_periodo)"
                                    v-model="form.condicoes[i].EMPRESA_CRIADO_EM.data_final"
                                    type="date"
                                    outlined
                                    dense
                                    hide-details
                                    style="max-width: 160px;"
                                />
                            </div>

                            <div v-if="form.condicoes[i].type === 'EMPRESA_DIAS_CADASTRADO'" style="display: flex; flex-wrap: wrap;">
                                <v-select
                                    @change="$forceUpdate()"
                                    :items="numberOptions"
                                    v-model="form.condicoes[i].EMPRESA_DIAS_CADASTRADO.operador_valor"
                                    dense
                                    outlined
                                    hide-details
                                    class="mx-2"
                                    style="max-width: 140px;"
                                />
                                <v-text-field
                                    v-if="['MAIOR_QUE', 'ENTRE'].includes(form.condicoes[i].EMPRESA_DIAS_CADASTRADO.operador_valor)"
                                    v-model="form.condicoes[i].EMPRESA_DIAS_CADASTRADO.valor_inicial"
                                    v-mask="['####']"
                                    outlined
                                    dense
                                    hide-details
                                    style="max-width: 60px;"
                                />
                                <v-text-field
                                    v-if="['MENOR_QUE', 'ENTRE'].includes(form.condicoes[i].EMPRESA_DIAS_CADASTRADO.operador_valor)"
                                    v-model="form.condicoes[i].EMPRESA_DIAS_CADASTRADO.valor_final"
                                    v-mask="['####']"
                                    outlined
                                    dense
                                    hide-details
                                    style="max-width: 60px;"
                                />
                            </div>

                            <div v-if="form.condicoes[i].type === 'EMPRESA_DIAS_ULTIMO_PEDIDO'" style="display: flex; flex-wrap: wrap;">
                                <v-select
                                    @change="$forceUpdate()"
                                    :items="numberOptions"
                                    v-model="form.condicoes[i].EMPRESA_DIAS_ULTIMO_PEDIDO.operador_valor"
                                    dense
                                    outlined
                                    hide-details
                                    class="mx-2"
                                    style="max-width: 140px;"
                                />
                                <v-text-field
                                    v-if="['MAIOR_QUE', 'ENTRE'].includes(form.condicoes[i].EMPRESA_DIAS_ULTIMO_PEDIDO.operador_valor)"
                                    v-model="form.condicoes[i].EMPRESA_DIAS_ULTIMO_PEDIDO.valor_inicial"
                                    v-mask="['####']"
                                    outlined
                                    dense
                                    hide-details
                                    style="max-width: 60px;"
                                />
                                <v-text-field
                                    v-if="['MENOR_QUE', 'ENTRE'].includes(form.condicoes[i].EMPRESA_DIAS_ULTIMO_PEDIDO.operador_valor)"
                                    v-model="form.condicoes[i].EMPRESA_DIAS_ULTIMO_PEDIDO.valor_final"
                                    v-mask="['####']"
                                    outlined
                                    dense
                                    hide-details
                                    style="max-width: 60px;"
                                />
                            </div>
                            <v-btn @click="form.condicoes.splice(i, 1)" small color="error" icon>
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </div>
                    </div>
                </div>

                <v-btn color="primary" @click="addCondicao" class="mt-5">
                    <v-icon>mdi-plus</v-icon>&nbsp;Adicionar Condição
                </v-btn>
            </v-card-text>
            <div class="ml-4">
                <span class="text-subtitle">Tamanho do público: <b>{{ form.tamanho_publico || 0 }}</b></span>
                <v-btn @click="calcularTamanhoPublico" icon color="primary" class="ml-2" :loading="tamanhoLoading">
                    <v-icon>mdi-refresh</v-icon>
                </v-btn>
            </div>
            <v-card-actions>
                <v-btn text color="warning" @click="$emit('close')">Cancelar</v-btn>
                <v-spacer />
                <v-btn color="success" @click="salvar" :loading="loading">
                    <v-icon>mdi-content-save</v-icon>&nbsp;Salvar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import FileInput from '@/components/FileInput';
import { mapState } from 'vuex';

export default {
    name: 'Publico',

    components: {
        FileInput,
    },

    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        formData: {
            type: Object,
            default: Object,
        },
    },

    data: () => ({
        loading: false,
        tamanhoLoading: false,
        form: {},
        canais: ['whatsapp'],
        publicos: [],
        condicoesClientes: [
            { value: 'PEDIDOS', text: 'Pedidos' },
            { value: 'VALOR_GASTO', text: 'Valor Gasto' },
            { value: 'DIAS_SEM_COMPRAR', text: 'Dias sem Comprar' },
            { value: 'CLIENTE', text: 'Código do Cliente' },
            // { value: 'VISITAS', text: 'Visitas' },
            // { value: 'CLIENTE_POTENCIAL', text: 'Cliente Potencial' },
        ],
        condicoesContatos: [
            { value: 'CONTATO_CODIGO', text: 'Código do Contato' },
            { value: 'CONTATO_CRIADO_EM', text: 'Data de Cadastro' },
            { value: 'CONTATO_RAMO', text: 'Segmento de Atuação' },
            { value: 'CONTATO_PEDIDOS', text: 'Pedidos por Dia' },
            { value: 'CONTATO_DIAS_CADASTRADO', text: 'Dias Cadastrado' },
        ],
        condicoesEmpresas: [
            { value: 'EMPRESA_CODIGO', text: 'Código da Empresa' },
            { value: 'EMPRESA_ATIVO', text: 'Ativo' },
            { value: 'EMPRESA_INADIMPLENTE', text: 'Inadimplente' },
            { value: 'EMPRESA_CRIADO_EM', text: 'Data de Cadastro' },
            { value: 'EMPRESA_DIAS_CADASTRADO', text: 'Dias Cadastrado' },
            { value: 'EMPRESA_DIAS_ULTIMO_PEDIDO', text: 'Dias desde o Último Pedido' },
        ],
        numberOptions: [
            { value: 'ENTRE', text: 'Entre' },
            { value: 'MAIOR_QUE', text: 'Maior que' },
            { value: 'MENOR_QUE', text: 'Menor que' },
        ],
        dateOptions: [
            { value: 'ENTRE', text: 'Entre' },
            { value: 'ANTES_DE', text: 'Antes de' },
            { value: 'DEPOIS_DE', text: 'Depois de' },
        ],
        textOptions: [
            { value: 'IGUAL', text: 'Igual' },
            { value: 'CONTEM', text: 'Contém' },
        ],
        booleanOptions: [
            { value: 'true', text: 'É' },
            { value: 'false', text: 'Não é' },
        ],
        fontes: [
            { value: 'clientes', text: 'Clientes' },
            { value: 'contatos', text: 'Contatos' },
            { value: 'empresas', text: 'Empresas' },
        ],
    }),

    mounted() {
        this.reset();
    },

    computed: {
        ...mapState([
            'usuario',
        ]),

        cssVars() {
            return {
                '--bg-color': '#3874d0',
                '--color': '#fff',
                'width': '100%',
            };
        },
    },

    watch: {
        formData(value) {
            value
                ? (this.form = JSON.parse(JSON.stringify(value)))
                : this.reset();
        },
    },

    methods: {
        reset() {
            this.form = {
                nome: '',
                fonte: 'clientes',
                mensagem: '',
                operador_condicional: 'Qualquer',
                tamanho_publico: null,
                condicoes: [],
            };
        },

        validate() {
            if (!this.form.nome) {
                this.notify('Preencha o nome da campanha', 'warning');
                return;
            }

            if (!this.form.condicoes.length) {
                this.notify('Nenhuma condição adicionada', 'warning');
                return;
            }

            if (!this.validateCondicoes()) {
                this.notify('Preencha todas as condições', 'warning');
                return;
            }

            return true;
        },

        async salvar() {
            await this.calcularTamanhoPublico();

            if (this.form.id) {
                this.loading = true;
                this.$http.put(`publicos/${this.form.id}`, this.form).then(() => {
                    this.$emit('onCadastro');
                    this.notify('Atualizado com sucesso!');
                }).catch(() => {
                    this.notify('Verifique sua conexão com a internet', 'warning');
                }).finally(() => (this.loading = false));
                return;
            }

            this.loading = true;
            this.$http.post('publicos', this.form).then(resp => {
                this.$emit('onCadastro', resp.data.data);
                this.notify('Salvo com sucesso!');
            }).catch(() => {
                this.notify('Verifique sua conexão com a internet', 'warning');
            }).finally(() => (this.loading = false));
        },

        validateCondicoes() {
            for (const condicao of this.form.condicoes) {
                if (!condicao.type) return false;

                switch (condicao.type) {
                    case 'PEDIDOS':
                        if (!condicao.PEDIDOS.operador_quantidade || 
                            (['MAIOR_QUE', 'ENTRE'].includes(condicao.PEDIDOS.operador_quantidade) && !condicao.PEDIDOS.quantidade_inicial) || 
                            (['MENOR_QUE', 'ENTRE'].includes(condicao.PEDIDOS.operador_quantidade) && !condicao.PEDIDOS.quantidade_final) ||
                            !condicao.PEDIDOS.operador_periodo ||
                            (['ANTES_DE', 'ENTRE'].includes(condicao.PEDIDOS.operador_periodo) && !condicao.PEDIDOS.data_inicial) || 
                            (['DEPOIS_DE', 'ENTRE'].includes(condicao.PEDIDOS.operador_periodo) && !condicao.PEDIDOS.data_final)
                        ) {
                            return false;
                        }
                        break;
                    case 'VALOR_GASTO':
                        if (!condicao.VALOR_GASTO.operador_valor || 
                            (['MAIOR_QUE', 'ENTRE'].includes(condicao.VALOR_GASTO.operador_valor) && !condicao.VALOR_GASTO.valor_inicial) || 
                            (['MENOR_QUE', 'ENTRE'].includes(condicao.VALOR_GASTO.operador_valor) && !condicao.VALOR_GASTO.valor_final) ||
                            !condicao.VALOR_GASTO.operador_periodo ||
                            (['ANTES_DE', 'ENTRE'].includes(condicao.VALOR_GASTO.operador_periodo) && !condicao.VALOR_GASTO.data_inicial) || 
                            (['DEPOIS_DE', 'ENTRE'].includes(condicao.VALOR_GASTO.operador_periodo) && !condicao.VALOR_GASTO.data_final)
                        ) {
                            return false;
                        }
                        break;
                    case 'DIAS_SEM_COMPRAR':
                        if (!condicao.DIAS_SEM_COMPRAR.operador_valor || 
                            (['MAIOR_QUE', 'ENTRE'].includes(condicao.DIAS_SEM_COMPRAR.operador_valor) && !condicao.DIAS_SEM_COMPRAR.valor_inicial) || 
                            (['MENOR_QUE', 'ENTRE'].includes(condicao.DIAS_SEM_COMPRAR.operador_valor) && !condicao.DIAS_SEM_COMPRAR.valor_final)
                        ) {
                            return false;
                        }
                        break;
                    case 'CLIENTE':
                        if (!condicao.CLIENTE.operador_codigo || 
                            (['MAIOR_QUE', 'ENTRE'].includes(condicao.CLIENTE.operador_codigo) && !condicao.CLIENTE.codigo_inicial) || 
                            (['MENOR_QUE', 'ENTRE'].includes(condicao.CLIENTE.operador_codigo) && !condicao.CLIENTE.codigo_final)
                        ) {
                            return false;
                        }
                        break;
                    case 'CONTATO_CODIGO':
                        if (!condicao.CONTATO_CODIGO.operador_codigo || 
                            (['MAIOR_QUE', 'ENTRE'].includes(condicao.CONTATO_CODIGO.operador_codigo) && !condicao.CONTATO_CODIGO.codigo_inicial) || 
                            (['MENOR_QUE', 'ENTRE'].includes(condicao.CONTATO_CODIGO.operador_codigo) && !condicao.CONTATO_CODIGO.codigo_final)
                        ) {
                            return false;
                        }
                        break;
                    case 'CONTATO_CRIADO_EM':
                        if (!condicao.CONTATO_CRIADO_EM.operador_periodo ||
                            (['ANTES_DE', 'ENTRE'].includes(condicao.CONTATO_CRIADO_EM.operador_periodo) && !condicao.CONTATO_CRIADO_EM.data_inicial) || 
                            (['DEPOIS_DE', 'ENTRE'].includes(condicao.CONTATO_CRIADO_EM.operador_periodo) && !condicao.CONTATO_CRIADO_EM.data_final)
                        ) {
                            return false;
                        }
                        break;
                    case 'CONTATO_RAMO':
                        if (!condicao.CONTATO_RAMO.operador_ramo || 
                            (condicao.CONTATO_RAMO.operador_ramo === 'IGUAL' && !condicao.CONTATO_RAMO.ramo_igual) || 
                            (condicao.CONTATO_RAMO.operador_ramo === 'CONTEM' && !condicao.CONTATO_RAMO.ramo_contem)
                        ) {
                            return false;
                        }
                        break;
                    case 'CONTATO_PEDIDOS':
                        if (!condicao.CONTATO_PEDIDOS.operador_pedidos || 
                            (condicao.CONTATO_PEDIDOS.operador_pedidos === 'IGUAL' && !condicao.CONTATO_PEDIDOS.pedidos_igual) || 
                            (condicao.CONTATO_PEDIDOS.operador_pedidos === 'CONTEM' && !condicao.CONTATO_PEDIDOS.pedidos_contem)
                        ) {
                            return false;
                        }
                        break;
                    case 'CONTATO_DIAS_CADASTRADO':
                        if (!condicao.CONTATO_DIAS_CADASTRADO.operador_valor || 
                            (['MAIOR_QUE', 'ENTRE'].includes(condicao.CONTATO_DIAS_CADASTRADO.operador_valor) && !condicao.CONTATO_DIAS_CADASTRADO.valor_inicial) || 
                            (['MENOR_QUE', 'ENTRE'].includes(condicao.CONTATO_DIAS_CADASTRADO.operador_valor) && !condicao.CONTATO_DIAS_CADASTRADO.valor_final)
                        ) {
                            return false;
                        }
                        break;
                    case 'EMPRESA_CODIGO':
                        if (!condicao.EMPRESA_CODIGO.operador_codigo || 
                            (['MAIOR_QUE', 'ENTRE'].includes(condicao.EMPRESA_CODIGO.operador_codigo) && !condicao.EMPRESA_CODIGO.codigo_inicial) || 
                            (['MENOR_QUE', 'ENTRE'].includes(condicao.EMPRESA_CODIGO.operador_codigo) && !condicao.EMPRESA_CODIGO.codigo_final)
                        ) {
                            return false;
                        }
                        break;
                    case 'EMPRESA_ATIVO':
                        if (condicao.EMPRESA_ATIVO.operador_ativo === undefined) {
                            return false;
                        }
                        break;
                    case 'EMPRESA_INADIMPLENTE':
                        if (condicao.EMPRESA_INADIMPLENTE.operador_inadimplente === undefined) {
                            return false;
                        }
                        break;
                    case 'EMPRESA_CRIADO_EM':
                        if (!condicao.EMPRESA_CRIADO_EM.operador_periodo ||
                            (['ANTES_DE', 'ENTRE'].includes(condicao.EMPRESA_CRIADO_EM.operador_periodo) && !condicao.EMPRESA_CRIADO_EM.data_inicial) || 
                            (['DEPOIS_DE', 'ENTRE'].includes(condicao.EMPRESA_CRIADO_EM.operador_periodo) && !condicao.EMPRESA_CRIADO_EM.data_final)
                        ) {
                            return false;
                        }
                        break;
                    case 'EMPRESA_DIAS_CADASTRADO':
                        if (!condicao.EMPRESA_DIAS_CADASTRADO.operador_valor || 
                            (['MAIOR_QUE', 'ENTRE'].includes(condicao.EMPRESA_DIAS_CADASTRADO.operador_valor) && !condicao.EMPRESA_DIAS_CADASTRADO.valor_inicial) || 
                            (['MENOR_QUE', 'ENTRE'].includes(condicao.EMPRESA_DIAS_CADASTRADO.operador_valor) && !condicao.EMPRESA_DIAS_CADASTRADO.valor_final)
                        ) {
                            return false;
                        }
                        break;
                    case 'EMPRESA_DIAS_ULTIMO_PEDIDO':
                        if (!condicao.EMPRESA_DIAS_ULTIMO_PEDIDO.operador_valor || 
                            (['MAIOR_QUE', 'ENTRE'].includes(condicao.EMPRESA_DIAS_ULTIMO_PEDIDO.operador_valor) && !condicao.EMPRESA_DIAS_ULTIMO_PEDIDO.valor_inicial) || 
                            (['MENOR_QUE', 'ENTRE'].includes(condicao.EMPRESA_DIAS_ULTIMO_PEDIDO.operador_valor) && !condicao.EMPRESA_DIAS_ULTIMO_PEDIDO.valor_final)
                        ) {
                            return false;
                        }
                        break;
                    default:
                        return false;
                }
            }
            return true;
        },

        addCondicao() {
            this.form.condicoes.push({});
        },

        async calcularTamanhoPublico() {
            const invalid = !this.validate();
            if (invalid) {
                return;
            }

            this.tamanhoLoading = true;
            await this.$http.post('publicos/obter-tamanho', this.form).then(resp => {
                this.form.tamanho_publico = resp.data.data;
                this.notify('Tamanho do publico atualizado!');
            }).catch(() => {
                this.notify('Verifique sua conexão com a internet', 'warning');
            }).finally(() => (this.tamanhoLoading = false));
        },

        onFonteChange() {
            this.form.tamanho_publico = null;
            this.form.condicoes = [];
        },
    },
}
</script>

<style scoped>
.v-card {
    padding: 0 15px 0 15px;
}

.v-btn--active {
    background-color: var(--bg-color) !important;
    color: var(--color) !important;
}
</style>
